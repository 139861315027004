.email-tracking {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .select-wrapper {
    position: relative;
    width: 200px;
  }
  
  .select-wrapper::after {
    content: '▼';
    font-size: 1rem;
    top: 6px;
    right: 10px;
    position: absolute;
  }
  
  select {
    appearance: none;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    width: 100%;
    font-size: 14px;
  }
  
  .download-csv {
    background-color: white;
    border: 1px solid #4CAF50;
    color: #4CAF50;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .download-csv::before {
    content: '⬇️';
    margin-right: 5px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f8f8f8;
    font-weight: normal;
    color: #888;
  }
  
  .recipient {
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 2px 6px;
    margin-bottom: 2px;
    display: inline-block;
  }
  
  .email-subject {
    font-weight: bold;
  }
  
  .sent-date, .first-click {
    font-size: 12px;
    color: #888;
  }
  
  .clicks {
    font-weight: bold;
  }
  
  .action-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }