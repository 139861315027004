.settings-container {
    display: flex;
    height: 100%;
    background-color: #f9f9f9;
  }
  
  .settings-sidebar {
    width: 250px;
    background-color: #fff;
    border-right: 1px solid #ccc;
    padding: 20px;
  }
  
  .settings-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .settings-sidebar ul li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }
  
  .settings-sidebar ul li.active {
    font-weight: bold;
    background-color: #f0f0f0;
  }
  
  .settings-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    overflow-y: auto;
  }
  
  .settings-content h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .setting-option {
    margin-bottom: 15px;
  }
  
  .setting-option label {
    font-size: 14px;
    color: #666;
  }
  
  /* Save/Cancel Bar */
  .save-bar {
    z-index: 9;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .save-button:hover, .cancel-button:hover {
    opacity: 0.9;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    text-align: center;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.modal-header {
    margin-bottom: 20px;
}

.modal-icon {
    font-size: 40px;
    color: red;
}

h2 {
    margin-top: 10px;
    font-size: 18px;
}

.modal-body p {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.warning-text {
    font-weight: bold;
    color: red;
}

.checkbox-label {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}

.modal-footer {
    margin-top: 20px;
}

.confirm-button,
.cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}

.confirm-button {
    background-color: #d32f2f;
    color: white;
}

.cancel-button {
    background-color: #ccc;
    color: #333;
}

.confirm-button:disabled {
    background-color: #f0a0a0;
    cursor: not-allowed;
}
