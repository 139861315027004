.click-detail {
    font-family: Arial, sans-serif;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .click-detail h1 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  
  .click-detail .section {
    margin-bottom: 16px;
  }
  
  .click-detail .section-title {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .click-detail .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .click-detail .info span {
    font-size: 14px;
  }
  
  .click-detail .opens {
    color: #4caf50;
    font-weight: bold;
  }  