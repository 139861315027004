.contact-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

h1 {
    font-size: 24px;
    font-weight: bold;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tabs button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px 20px;
    /* Added padding for better button size */
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    /* Adjusted font size for better readability */
    color: #333;
    /* Default text color */
    transition: background-color 0.3s, color 0.3s;
    /* Added transition for smooth hover effect */
}

.tabs button.active {
    background-color: #007bff;
    /* Active tab background */
    border-color: #007bff;
    color: #fff;
    /* Active tab text color */
    border-bottom: 2px solid #007bff;
    /* Adding a highlight for active state */
}

.tabs button:hover {
    background-color: #d9d9d9;
    /* Light gray hover color */
}

.tab-content {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Extra CSS to fix layout and alignment issues */
.contact-container {
    max-width: 1200px;
    /* Limit the container width */
    margin: 0 auto;
    /* Center the container */
}

.contact-container h2,
.contact-container h1 {
    margin-bottom: 20px;
}

.csv-button {
    margin-left: auto;
}

.communications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.communications-table {
    width: 100%;
    border-collapse: collapse;
}

.communications-table th,
.communications-table td {
    border: 1px solid #ddd;
    padding: 10px;
}

.communications-table th {
    background-color: #f5f5f5;
}

.profile-status {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-status .consent-status {
    font-weight: bold;
    margin-left: 10px;
}

.resend-consent-btn {
    margin-left: 20px;
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.profile-privacy {
    margin-bottom: 20px;
}

.profile-notes textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}