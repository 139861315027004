/* General Layout */
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #007bff;
  color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-actions {
  display: flex;
  gap: 1rem;
}

.header-actions .icon-btn, .header-actions .user-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #ffffff;
}

.content {
  display: flex;
  flex-grow: 1;
}

/* Sidebar Styling */
.sidebar {
  width: 250px;
  background-color: #2c3e50;
  padding: 1rem;
  color: #ffffff; /* Ensure sidebar text is white */
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
}

.sidebar nav ul li {
  margin-bottom: 1rem;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #ffffff; /* White text for visibility */
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: 600; /* Increase font weight for readability */
  transition: background-color 0.3s, color 0.3s;
}

.sidebar nav ul li a:hover {
  background-color: #34495e;
  color: #ecf0f1;
}

.sidebar nav ul li a i {
  margin-right: 10px;
  font-size: 1.2rem;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  padding: 2rem;
  background-color: #ecf0f1;
  color: #2c3e50; /* Darker text for readability */
}

/* Footer Styling */
.footer {
  background-color: #2c3e50;
  color: #ffffff; /* Ensure the footer text is white */
  text-align: center;
  padding: 1rem;
  font-weight: 500;
  font-size: 0.9rem;
  position: relative;
  bottom: 0;
  width: 100%;
}
