.email-tracking {
  font-family: Arial, sans-serif;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.select-wrapper {
  position: relative;
  width: 200px;
}

.select-wrapper::after {
  content: '▼';
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

select {
  appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  width: 100%;
  font-size: 14px;
}

.download-csv {
  background-color: white;
  border: 1px solid #4CAF50;
  color: #4CAF50;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.download-csv::before {
  content: '⬇️';
  margin-right: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f8f8f8;
  font-weight: normal;
  color: #888;
}

.recipient {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 2px 6px;
  margin-bottom: 2px;
  display: inline-block;
}

.email-subject {
  font-weight: bold;
}

.sent-date,
.first-open {
  font-size: 12px;
  color: #888;
}

.opens {
  font-weight: bold;
}

.action-button {
  background: grey;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.action-wrapper {
  position: relative;
}

.popup-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
}

.popup-item:hover {
  background-color: #f5f5f5;
}