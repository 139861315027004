.contacts-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .contacts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .search-bar input {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .filter {
    display: flex;
    align-items: center;
  }
  
  .filter input {
    margin-right: 5px;
  }
  
  .csv-download {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #28a745;
    font-weight: bold;
  }
  
  .csv-icon {
    margin-right: 5px;
  }
  
  .contacts-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .contacts-table th,
  .contacts-table td {
    border-bottom: 1px solid #ddd;
    padding: 12px 8px;
    text-align: left;
  }
  
  .contacts-table th {
    background-color: #f9f9f9;
  }
  
  .contacts-table td {
    font-size: 14px;
  }
  
  p {
    margin-bottom: 5px;
  }  