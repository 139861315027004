.email-productivity-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .stats-container {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 0 auto; /* Center the stats-container */
  }
  
  .stats-item {
    text-align: center;
  }
  
  .stats-item h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .stats-item .count {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .stats-item .percentage {
    font-size: 1rem;
    color: #4CAF50; /* Green for positive percentage */
  }
  
  .date-selector {
    font-size: 1rem;
    color: #333;
  }
  
  .date-selector select {
    padding: 5px 10px;
    font-size: 1rem;
  }
  
  .email-productivity-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal-width columns */
    gap: 20px; /* Space between the columns */
    width: 100%; /* Make sure it takes full width */
  }
  
  .card-item {
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  