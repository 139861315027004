.profile-status {
    display: flex;
    align-items: center;
    gap: 10px;
}

.consent-status {
    font-weight: bold;
}

.consent-not-status {
    font-size: smaller;
    color: grey;
}

/* Make the edit button visible always */
.edit-consent-btn {
    background: none;
    /* No background */
    border: none;
    /* No border */
    cursor: pointer;
    font-size: 18px;
    /* Increase icon size */
    padding: 0;
    color: #007bff;
    /* Set a visible color (blue) */
}

.edit-consent-btn:hover {
    color: #f0f0f0;
    /* Darken the color on hover */
}

.resend-consent-btn {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.resend-consent-btn:hover {
    background-color: #0056b3;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.save-btn,
.cancel-btn {
    padding: 8px 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.save-btn {
    background-color: #28a745;
    color: white;
}

.cancel-btn {
    background-color: #dc3545;
    color: white;
}

.save-btn:hover {
    background-color: #218838;
}

.cancel-btn:hover {
    background-color: #c82333;
}